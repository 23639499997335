import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import AppLayout from "./layouts/AppLayout";
import "./styles/main.scss";
import * as ProductUtils from "./utils/product";
import ScrollLoader from "vue-scroll-loader";
import PlaceholderImg from "./assets/img/placeholder.png";
import VConsole from "vconsole";
import * as Sentry from "@sentry/vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";

// Register it globally
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);


Sentry.init({
  Vue,
  dsn:
    "https://708b2d2abdec4c7196032f341c0d23eb@o349622.ingest.sentry.io/5800280",
  release: "sale-ordering-line@" + process.env.VUE_APP_COMMIT_REF,
});

const urlParams = new URLSearchParams(window.location.search);

Vue.use(ScrollLoader);

Vue.component("AppLayout", AppLayout);

Vue.config.productionTip = false;

const checkVconsole = urlParams.get("vconsole");

// check query string have vconsole == 'on'
if (checkVconsole) {
  if (checkVconsole == "1") {
    try {
      window.vConsole = new VConsole();
    } catch (error) {
      console.log(error);
    }
  }
}

Vue.mixin({
  computed: {
    $isSeller() {
      return window.$permission ? window.$permission.isSeller : null;
    },
    $isBuyer() {
      return window.$permission ? window.$permission.isBuyer : null;
    },
    $myShop() {
      return window.$permission?.myShop || {};
    },
    $sellerShop() {
      return window.$permission.sellerShop || {};
    },
    $shopType() {
      return window.$permission.type || null;
    },
    $primaryColor() {
      return getComputedStyle(document.body).getPropertyValue(
        "--primary-color"
      );
    },
  },
  methods: {
    ...ProductUtils,
    $formatDate(date) {
      return new Intl.DateTimeFormat("th", {
        year: "2-digit",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      }).format(new Date(date));
    },
  },
});

Vue.prototype.$placeholderImg = PlaceholderImg;

Object.defineProperty(Object.prototype, "toPrice", {
  // enumerable: false, // set to non-enumerable, the default value is already false
  // configurable, writable, etc. set as needed
  value: function() {
    return this.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  },
});

window.$pushEvent = (c, a, n) => {
  try {
    window["_paq"].push(["trackEvent", c, a, n]);
  } catch (error) {
    console.log(error);
  }
};

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
