<template>
  <Transition>
    <div v-if="openModal" :class="'modalConsent'">
      <div class="termCondition">
        <div class="title">{{ consents[activeConsentIndex].title }}</div>
        <div class="sub">
          ข้อกำหนดและเงื่อนไขในการบริการ Chang Family Line OA
          และประกาศว่าด้วยการคุ้มครองข้อมูลส่วนบุคคลของ บริษัท ช้างอินเตอร์
          เนชั่นแนล
        </div>
        <div class="containBody">
          <div ref="wrapContent" class="wrapContent">
            <div
              class="content"
              v-html="consents[activeConsentIndex].content"
            ></div>
          </div>
          <div class="bottomSection">
            <div class="suggest">
              เพื่อป้องกันสิทธิของผู้ใช้บริการ
              โปรดอ่านเงื่อนไขการให้บริการทั้งหมดและตกลงที่จะปฏิบัติตามข้อตกลงและเงื่อนไขทั้งหมดต่อไปนี้
            </div>
            <div class="checkboxSection">
              <div
                v-if="
                  !consents[activeConsentIndex].subConsent ||
                  consents[activeConsentIndex].subConsent.length === 0
                "
              >
                <ConsentCheckboxItem
                  :c="consents[activeConsentIndex]"
                  :id="`consent-section-input-${consents[activeConsentIndex].id}`"
                  v-model="consents[activeConsentIndex].isAccept"
                  :required="consents[activeConsentIndex].isForce"
                />
              </div>
              <template v-for="sc in consents[activeConsentIndex].subConsent">
                <ConsentCheckboxItem
                  :sc="sc"
                  :c="consents[activeConsentIndex]"
                  :id="`consent-section-input-${consents[activeConsentIndex].id}-${sc.subConsentId}`"
                  v-model="sc.isAccept"
                  :required="sc.isRequired"
                  :key="`${consents[activeConsentIndex].id}-${sc.subConsentId}`"
                />

                <!-- <div :key="activeConsentIndex + '_' + index">
              <div class="round">
                <input
                  type="checkbox"
                  :id="'ckb-consent-sub-' + sub.subConsentId"
                  v-model="sub.isAccept"
                />
                <label :for="'ckb-consent-sub-' + sub.subConsentId"></label>
              </div>

              <label
                class="checkbox-description"
                :for="'ckb-consent-sub-' + sub.subConsentId"
                style="position: relative"
              >
                <div class="required">{{ sub.isRequired ? "*" : "" }}</div>
                <div v-html="parseText(sub.description)"></div>
              </label>
            </div> -->
              </template>
            </div>
            <div
              :class="[{ disable: !currentPassed }, 'confirmBtn']"
              @click="onNext()"
            >
              ถัดไป
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import { GET, POST } from "@/utils/http";
import ConsentCheckboxItem from "@/components/ConsentCheckboxItem.vue";

export default {
  components: { ConsentCheckboxItem },
  data() {
    return {
      openModal: false,
      consents: [],
      activeConsentIndex: 0,
    };
  },
  mounted() {},
  computed: {
    checkConsent() {
      return this.$store.state.consent.check;
    },
    currentPassed() {
      const consent = this.consents[this.activeConsentIndex];
      if (consent?.subConsent?.length > 0) {
        return !consent.subConsent.some((s) => s.isRequired && !s.isAccept);
      }
      return consent.isForce && consent.isAccept;
    },
  },
  watch: {
    consentIndex: {
      handler() {
        this.$refs.wrapContent.scrollTo(0, 0);
      },
    },
    checkConsent: {
      immediate: true,
      async handler(v) {
        if (v === true) {
          await this.fetchConsent();
          if (this.consents.length > 0) {
            this.openModal = true;
          } else {
            this.$emit("finish");
          }
        }
      },
    },
  },
  methods: {
    close() {
      this.openModal = false;
      this.consents = [];
      this.activeConsentIndex = 0;
    },
    parseText(html) {
      return html.replace(/(<([^>]+)>)/gi, "");
    },
    async fetchConsent() {
      window.loading(true);
      try {
        const { status, body } = await GET(`/api/consents/waiting`);

        if (status !== 200) {
          throw "no case " + status;
        }
        this.consents = body;
      } catch (error) {
        console.error("get consent error: ", error);
        this.$emit("error", error);
        this.close();
      } finally {
        window.loading(false);
      }
    },
    async submitConsent() {
      window.loading(true);
      try {
        const submit_list = this.consents.map((c) => ({
          consentId: c.id,
          isAccept: c.subConsent && c.subConsent.length > 0 ? true : c.isAccept,
          ...(c.subConsent &&
            c.subConsent.length > 0 && {
              subConsentAccept: c.subConsent.map((s) => ({
                subConsentId: s.subConsentId,
                isAccept: s.isAccept || false,
              })),
            }),
        }));

        const payload = {
          data: submit_list,
        };

        const { status } = await POST(`/api/consents`, payload);

        if (status !== 201 && status !== 200) {
          throw "not ok case " + status;
        }
      } catch (error) {
        console.error("post consents error: ", error);
      } finally {
        window.loading(false);
      }
    },
    async onNext() {
      if (this.activeConsentIndex >= this.consents.length - 1) {
        await this.submitConsent();
        this.close();
        this.$emit("finish");
      } else {
        this.activeConsentIndex++;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

/* The Modal (background) */
.modalConsent {
  display: flex; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1040; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.termCondition {
  background: url("~@/assets/img/chang-register-bg.jpg"),
    linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  // background-blend-mode: overlay;
  background-size: cover;
  min-height: 100%;
  text-align: center;
  font-size: 15px;
  display: flex;
  flex-direction: column;
}

.termCondition {
  color: #2c2c2c;
}

.title,
.sub,
.confirmBtn {
  text-shadow: 1px 1px #000000;
}

.title {
  font-size: 20px;
  background: #001b0666;
  padding: 14px;
  color: white;
  font-weight: bold;
  margin-bottom: 10px;
}

.sub {
  color: white;
  font-size: 13px;
  line-height: 18px;
  margin: 10px;
}

.containBody {
  background: #e5e5e7;
  padding: 10px;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.wrapContent {
  // height: 58vh;
  // padding: 10px;
  overflow: auto;
}

.content {
  overflow: auto;
  box-shadow: 0 0 3px #00000025;
  border-radius: 10px;
  margin: 8px;
  padding: 10px;
  font-size: 14px;
  line-height: 20px;
  text-align: unset;
  background: #ffffff;
}

.suggest {
  font-size: 12px;
  padding-top: 10px;
  line-height: 18px;
}

.confirmBtn {
  margin: 10px auto 0 auto;
  max-width: 400px;
  display: flex;
  border-radius: 10px;
  justify-content: center;
  background: var(--primary-color);
  align-items: center;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5);
  height: 40px;
  color: #ffffff;
  &.disable {
    pointer-events: none;
    background: #696969b0;
    box-shadow: none;
    color: #a3a3a3;
  }
}

.checkboxSection {
  margin: 0 auto;
  padding-left: 15px;
  label {
    margin-left: 5px;
    margin-right: 4px;
    text-align: left;
    line-height: 18px;
    font-size: 13px;
  }
  span {
    text-decoration: underline;
  }
}
</style>