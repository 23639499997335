<template>
  <div id="app">
    <!-- <button @click="testText = 'AAAAAAAAA'">Click</button> -->
    <AppLayout>
      <!-- <transition :name="transitionName" appear mode="out-in"> -->
      <router-view />
      <!-- </transition> -->
    </AppLayout>
    <BottomMenu v-if="$route.meta.mainMenu === true" />
    <Alert />
    <UserConsentsModal />
  </div>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";
import Alert from "@/components/Alert";
import BottomMenu from "@/components/BottomMenu";
import UserConsentsModal from "@/components/UserConsentsModal.vue";

export default {
  data() {
    return {
      transitionName: "slide-left",
    };
  },
  components: { AppLayout, Alert, BottomMenu, UserConsentsModal },
  methods: {
    closeModal() {},
  },
  watch: {
    $route(to, from) {
      const toDepth = to.path.split("/").length;
      const fromDepth = from.path.split("/").length;
      this.transitionName = toDepth >= fromDepth ? "slide-left" : "slide-right";
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
#app {
  font-family: "Sarabun", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
  -webkit-touch-callout: none;
  height: 100%;
  -webkit-tap-highlight-color: transparent;
  // overflow: auto;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

*:not(textarea) {
}

textarea {
  -webkit-user-select: text !important;
  -khtml-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
  user-select: text !important;
}

html,
body {
  height: 100%;
}

body {
  -webkit-overflow-scrolling: touch;
  -webkit-user-drag: none;
  scrollbar-width: none;
  margin: 0;
}

body.modal-open {
  overflow: hidden;
  width: 100%;
}

body.search-open {
  overflow: hidden;
  width: 100%;
  bottom: 0;
  left: 0;
  // position: fixed;
  position: relative;
  right: 0;
  top: 0;
  height: 100vh;
}

::-webkit-scrollbar {
  display: none;
  -webkit-appearance: none;
  width: 0;
  height: 0;
}

::-webkit-input-placeholder {
  color: rgb(179, 179, 179) !important;
  opacity: 1;
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  // font-size: 100%;
  // font: inherit;
  // vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}

.slide-left-enter,
.slide-right-leave-active {
  transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1);
  opacity: 0;
  -webkit-transform: translate(30px, 0);
  transform: translate(30px, 0);
}
.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1);

  -webkit-transform: translate(-30px, 0);
  transform: translate(-30px, 0);
}
</style>
