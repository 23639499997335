import Vue from 'vue'
import Vuex from 'vuex'
import pathify from 'vuex-pathify'
import { make } from 'vuex-pathify'
import cart from '@/store/cart'
import showProduct from '@/store/showProduct'
import consent from '@/store/consent'

Vue.use(Vuex)

const state = {}
const mutations = {}

const store = new Vuex.Store({
  plugins: [pathify.plugin],
  state,
  mutations: { ...mutations, ...make.mutations(state) },
  actions: {},
  modules: {
    cart,
    showProduct,
    consent
  }
})

export default store
