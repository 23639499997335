<template>
  <div class="menu">
    <!-- <div
      v-for="menu in menuList"
      :key="menu.route"
      @click="clickMenu(menu.route)"
      :class="{ active: menu.route === selectedMenu }"
    >
      <img v-show="menu.route === selectedMenu" :src="menu.imgActive" />
      <img v-show="menu.route !== selectedMenu" :src="menu.img" />
      <div>{{ menu.name }}</div>
    </div> -->
    <div
      v-if="$isSeller"
      @click="clickMenu('/manage-product')"
      :class="{ active: '/manage-product' === selectedMenu }"
    >
      <ProductIcon />
      <div>สินค้า</div>
    </div>
    <div
      v-if="$isSeller || $isBuyer"
      @click="clickMenu('/products')"
      :class="{ active: '/products' === selectedMenu }"
    >
      <ShopIcon />
      <div v-if="$isSeller">ร้านค้า</div>
      <div v-if="$isBuyer">สั่งสินค้า</div>
    </div>
    <div
      v-if="$isSeller"
      @click="clickMenu('/report')"
      :class="{ active: '/report' === selectedMenu }"
    >
      <ReportIcon />
      <div>รายงาน</div>
    </div>
    <div
      v-if="$isSeller || $isBuyer"
      @click="clickMenu('/order')"
      :class="{ active: '/order' === selectedMenu }"
    >
      <OrderIcon />
      <div>ออเดอร์</div>
    </div>

    <div
      v-if="$isSeller || $isBuyer"
      @click="clickMenu('/notification')"
      :class="{ active: '/notification' === selectedMenu }"
    >
      <NotificationIcon />
      <div>แจ้งเตือน</div>
    </div>

    <div
      v-if="$isSeller || $isBuyer"
      @click="clickMenu('/settings')"
      :class="{ active: '/settings' === selectedMenu }"
    >
      <SettingIcon />
      <div>ตั้งค่า</div>
    </div>
  </div>
</template>
<script>
// import shopImg from "@/assets/bottomMenu/shop.svg";
// import shopImgActive from "@/assets/bottomMenu/shop_active.svg";
// import boxImg from "@/assets/bottomMenu/box.svg";
// import boxImgActive from "@/assets/bottomMenu/box_active.svg";
// import settingsImg from "@/assets/bottomMenu/settings.svg";
// import settingsImgActive from "@/assets/bottomMenu/settings_active.svg";
import ShopIcon from "@/components/menuIcon/ShopIcon.vue";
import ProductIcon from "@/components/menuIcon/ProductIcon.vue";
import SettingIcon from "@/components/menuIcon/SettingIcon.vue";
import OrderIcon from "@/components/menuIcon/OrderIcon.vue";
import NotificationIcon from "@/components/menuIcon/NotificationIcon.vue";
import ReportIcon from "@/components/menuIcon/ReportIcon.vue";

export default {
  name: "BottomMenuLayout",
  components: {
    ShopIcon,
    ProductIcon,
    SettingIcon,
    OrderIcon,
    NotificationIcon,
    ReportIcon,
  },
  data() {
    return {
      selectedMenu: "",
      menuList: [
        // {
        //   name: "ร้านค้า",
        //   img: shopImg,
        //   imgActive: shopImgActive,
        //   route: "/products",
        // },
        // {
        //   name: "สินค้า",
        //   img: boxImg,
        //   imgActive: boxImgActive,
        //   route: "/manage-product",
        // },
        // {
        //   name: "ตั้งค่า",
        //   img: settingsImg,
        //   imgActive: settingsImgActive,
        //   route: "/settings",
        // },
      ],
    };
  },
  watch: {
    $route() {
      this.selectedMenu = this.$route.matched[0].path;
    },
  },
  methods: {
    logoUrl(name) {
      return require(`../assets/bottomMenu/${name}`);
    },
    clickMenu(routeName) {
      document.querySelector("#app").scrollTo(0, 0);
      if (this.$route.name !== routeName) {
        this.$router.push({ path: routeName }).catch(() => {});
      }
    },
  },
  mounted() {
    this.selectedMenu = this.$route.matched[0].path;
  },
};
</script>

<style lang="scss" scoped>
.menu {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 57px;
  background: #fff;
  z-index: 2;
  width: 100%;
  bottom: 0;
  left: 0;
  position: fixed;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  box-shadow: 0px 0px 9px 0px rgb(101 101 101 / 15%);
  color: #646464;
  > div {
    font-size: 11px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    > img,
    svg {
      width: 100%;
      height: auto;
      max-width: 22px;
      margin-top: 5px;
      margin-bottom: 5px;
      fill: #888888;
    }
    &.active {
      &::after {
        content: "";
        position: absolute;
        width: 36%;
        margin-left: auto;
        margin-right: auto;
        right: 0;
        top: 0;
        left: 0;
        background: var(--primary-color);
        border-radius: 6px;
        height: 2px;
      }
      color: var(--primary-color);
      svg {
        fill: var(--primary-color);
      }
    }
  }
}
</style>