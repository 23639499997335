import Auth from "./Auth";

// eslint-disable-next-line no-unused-vars
export default async function(to, from, next) {
  window.loading(true);
  try {
    if (to.meta.auth !== false) {
      await Auth(to, from, next);
    }
    return next();
  } catch (error) {
    console.error("middleware error: ", error);
    return next({ name: "error", params: error, replace: true });
  } finally {
    window.loading(false);
  }
}
