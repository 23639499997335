export const getHeader = () => {
    if (window.$apiToken) {
        return {
            Authorization: `Bearer ${window.$apiToken}`,
            // 'Content-Type': 'application/json',
        }
    }
    return {
        // 'Content-Type': 'application/json',
    }
}

const baseURL = process.env.VUE_APP_API_URL

export const API_REQUEST = async (...arg) => {
    let method = arg[0] || 'GET',
        URL = arg[1],
        resType = arg[3] || 'json'

    let signal = arg[4] || null
    try {

        let body = arg[2] || null;

        const headers = getHeader()

        if (!(body instanceof FormData)) {
            if (typeof body === 'object' && body !== null) {
                headers["Content-Type"] = "application/json"
                body = JSON.stringify(arg[2])
            }
        }

        const res = await fetch(
            `${baseURL}${URL}`,
            {
                method,
                headers,
                body: body ? body : null,
                signal: signal
            }
        )

        const getBody = async () => {
            if (res.status === 204) return '';
            switch (resType) {
                case 'text':
                    return await res.text()
                default:
                    return await res.json()
            }
        }

        const responseBody = await getBody()

        if (res.status === 401) {
            if (responseBody.message === "invalid or expired jwt") {
                window.$pushEvent(`${baseURL}${URL}`, 'httpError', "invalid or expired jwt")
                await window.$alert("<h4>เซสชั่นหมดอายุ</h4>กรุณาปิดหน้านี้และเข้าใช้งานอีกครั้ง")
                window.close()
            }
        }

        if (res.status === 500) {
            window.$pushEvent(`${baseURL}${URL}`, 'httpError', '500:' + res.responseBody)
        }

        return {
            status: res.status,
            ...(responseBody && { body: responseBody }),
        }


    } catch (e) {
        console.error(e);
        window.$pushEvent(`${baseURL}${URL}`, 'httpError', e)
        throw e
    }
}

// connect function
// const request = (...arg) => API_REQUEST(arg)

// method function
const GET = async (...arg) => await API_REQUEST('GET', ...arg)
const POST = async (...arg) => await API_REQUEST('POST', ...arg)
const PUT = async (...arg) => await API_REQUEST('PUT', ...arg)
const PATCH = async (...arg) => await API_REQUEST('PATCH', ...arg)
const DELETE = async (...arg) => await API_REQUEST('DELETE', ...arg)
export { GET, POST, PUT, PATCH, DELETE }

// convert formData to json
export const serializeForm = (form) => {
    let obj = {}
    const formData = new FormData(form)
    for (let key of formData.keys()) {
        obj[key] = formData.get(key)
    }
    return obj
}
