const state = {
    product: {},
    showModal: false
}

const actions = {
    showProduct(com, data) {
        console.log('show', data)
        state.product = data
        state.showModal = true
    },
    close() {
        state.product = {}
        state.showModal = false
    }
}

export default {
    namespaced: true,
    state,
    actions
}