import { mock } from "../../devConfig";
import jwtDecode from "jwt-decode";
import { isBuyer, isSeller } from "../permission/shop";
import errorCode from "../utils/errorCode";
import store from "@/store";
import * as Sentry from "@sentry/vue";

const API_URL = process.env.VUE_APP_API_URL;

// eslint-disable-next-line no-unused-vars
let to = null,
  //   from = null,
  next = null;
// eslint-disable-next-line no-unused-vars
export default async function Auth(_to, _from, _next) {
  to = _to;
  //   from = _from;
  next = _next;

  // already token
  if (window.$apiToken) {
    await setPermission();
    return;
  }

  // check and set mock
  if (process.env.NODE_ENV !== "production") {
    if (mock.apiToken) {
      console.log("- use mock apiToken");
      console.log("mock.apiToken:", mock.apiToken);
      window.$apiToken = mock.apiToken;
      window.$apiTokenDecode = jwtDecode(window.$apiToken);
      await checkConsent();
      await setPermission();
      return;
    }

    if (mock.extToken) {
      console.log("- use mock extToken");
      window.$extToken = mock.extToken;
    }

    if (mock.extApp) {
      console.log("- use mock extApp");
      window.$extApp = mock.extApp;
    }

    if (mock.extKey) {
      console.log("- use mock extKey");
      window.$extKey = mock.extKey;
    }
  }
  ///

  const accessToken = getHashValue("access_token");
  console.log("- accessToken", accessToken);

  const urlParams = new URLSearchParams(window.location.search);
  const extToken = urlParams.get("extToken");
  const extApp = urlParams.get("extApp");
  const extKey = urlParams.get("extKey");

  window.$extToken = extToken || window.$extToken;
  window.$extTokenDecode = jwtDecode(window.$extToken);

  window.$extApp = extApp || window.$extApp;
  window.$codeToken = accessToken || window.$codeToken;

  window.$extKey = extKey || window.$extKey;

  console.log("extApp", window.$extApp);
  console.log("extToken", window.$extToken);
  console.log("extKey", window.$extKey);
  console.log("codeToken", window.$codeToken);

  if (window.$codeToken) {
    if (!window.$authToken) {
      await AuthAccessToken();
    }

    if (to.meta.authUser !== false) {
      await AuthSSO();
      await setUserSentry();
      await checkConsent();
      await setPermission();
      return;
    }
    return;
  }

  if (!window.$extToken && !window.$extApp) {
    throw errorCode(101);
  }

  window.location = `${API_URL}/api/auth?application=${
    window.$extApp
  }&redirectUrl=${encodeURIComponent(window.location.href.split("#")[0])}`;

  await new Promise((resolve) => setTimeout(resolve, 15000));
}

function cleanTargetPath(to) {
  const passQuery = to.query;
  delete passQuery["extApp"];
  delete passQuery["extKey"];
  delete passQuery["extToken"];
  return encodeURIComponent(
    to.path + "?" + new URLSearchParams(passQuery).toString()
  );
}

async function setPermission() {
  try {
    if (to.meta.authUser === false) {
      return;
    }
    if (to.name === "ChooseBranch") {
      return;
    }
    if (to.name === "ChooseSellers") {
      console.log("step ChooseSellers");
      return;
    }

    if (!window.$permission) window.$permission = {};

    if (!window.$permission.myShop) {
      // more branch then one ?
      if (window.$apiTokenDecode.s.length > 1) {
        next(`/chooseBranch?targetPath=${cleanTargetPath(to)}`);
        return;
      } else {
        window.$permission.myShop = window.$apiTokenDecode.s[0];
      }
    }

    if (window.$permission.isSeller === undefined) {
      window.$permission.isSeller = await isSeller();
    }

    if (window.$permission.isBuyer === undefined) {
      window.$permission.isBuyer = await isBuyer();
    }

    if (to.name === "ChoosePermission") {
      return;
    }

    // if no any shop type
    if (!window.$permission.isSeller && !window.$permission.isBuyer) {
      throw errorCode(201);
    }

    // check multi shop type
    if (window.$permission.isSeller && window.$permission.isBuyer) {
      next(`/choosePermission?targetPath=${cleanTargetPath(to)}`);
      return;
    } else {
      // set variable if only one type
      if (window.$permission.isSeller) {
        window.$permission.type = "seller";
      }
      if (window.$permission.isBuyer) {
        window.$permission.type = "buyer";
      }
    }

    if (!window.$permission.sellerShop && window.$permission.type == "buyer") {
      console.log("next ChooseSellers");
      next(`/chooseSellers?targetPath=${cleanTargetPath(to)}`);
    }

    // check route shopType allow
    if (to.matched[0].meta.shopType) {
      if (to.matched[0].meta.shopType.includes(window.$permission.type)) {
        return;
      } else {
        throw errorCode(202);
      }
    }

    // check route shopType allow (old method)
    if (to.matched[0].meta.isSeller === true && !window.$permission.isSeller) {
      throw errorCode(202);
    }
    if (to.matched[0].meta.isBuyer === true && !window.$permission.isBuyer) {
      throw errorCode(202);
    }
  } catch (error) {
    console.error(error);
    next({ name: `error`, params: error });
  }
  return;
}

async function checkConsent() {
  if (to.meta.consent !== false) {
    store.commit("consent/CHECK", true);
  }

  // try {
  //     if (to.meta.authUser === false) { return }

  //     if (window.$apiTokenDecode.u.id != null) {
  //         const res = await GET(`/api/users/${window.$apiTokenDecode.u.id}/consents/waiting`);

  //         if (res.status !== 200) {
  //             throw errorCode(102)
  //         }

  //         if (res.body.length > 0) {
  //             next({ path: `/chang/condition-update` })
  //         }
  //     }

  // } catch (error) {
  //     console.error(error)
  //     next({ name: `error`, params: error })
  // }
  // return
}

function getHashValue(key) {
  var matches = location.hash.match(new RegExp(key + "=([^&]*)"));
  return matches ? matches[1] : null;
}

async function AuthAccessToken() {
  const res = await fetch(
    `${API_URL}/api/access_token?application=${window.$extApp}&code=${window.$codeToken}`,
    {
      method: "GET",
    }
  );

  if (res.status !== 200) {
    throw errorCode(102);
  }

  const { access_token } = await res.json();

  if (!access_token) {
    throw errorCode(103);
  }

  window.$authToken = access_token;
}

async function AuthSSO() {
  const reqBody = {
    channel: "sso",
    credential: {
      key: window.$extKey,
      accessToken: window.$extToken,
    },
  };

  const res = await fetch(`${API_URL}/api/auth`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${window.$authToken}`,
    },
    body: JSON.stringify(reqBody),
  });

  if (res.status === 401) {
    if (to.meta.authUser === false) {
      return;
    }

    next({ path: `/${window.$extApp}/register` });
    return;
    // throw { user_message: "Don't have access, Please <w>contact us</w>" }
  }

  console.log("res.status", res.status);
  if (res.status === 200) {
    const { access_token } = await res.json();
    if (!access_token) {
      throw errorCode(104);
    }

    window.$userData = jwtDecode(access_token);

    if (window.$userData.u.id === 0) {
      throw errorCode(105);
    }

    if (window.$userData.s.length === 0) {
      throw errorCode(106);
    }

    window.$apiToken = access_token;
    window.$apiTokenDecode = jwtDecode(window.$apiToken);
    console.log("set api token");
    return;
  }

  throw errorCode(107);
}

function setUserSentry() {
  // set userId for tracking
  try {
    // set userId tracking
    window.$apiTokenDecode?.u?.id &&
      Sentry.setUser({ id: window.$apiTokenDecode?.u?.id });

    // errorHandler.setUser(userIdTrack);
  } catch (err) {
    console.error(err);
  }
}
