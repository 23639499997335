<template>
  <div class="consent-item">
    <div class="round">
      <input
        type="checkbox"
        :id="id"
        @input="$emit('input', $event.target.checked)"
      />
      <label :for="id"></label>
    </div>
    <div class="checkBoxText">
      <div class="required">
        {{ required ? "*" : "" }}
      </div>
      <label :for="id"
        >{{ parseText(sc ? sc.description : c.agreeText) }}
      </label>
      <div v-if="clickHere" class="consentLink" @click="openConsent(c)">
        คลิกที่นี่
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sc: Object,
    c: Object,
    id: String,
    required: Boolean,
    clickHere: Boolean,
  },
  methods: {
    openConsent(c) {
      this.$emit("openConsent", c);
      console.log("open", c);
    },
    parseText(html) {
      return html.replace(/(<([^>]+)>)/gi, "");
    },
  },
};
</script>

<style lang="scss" scoped>
.consent-item {
  line-height: 25px;
  display: flex;
  align-items: center;
  text-align: left;
  margin: 10px 0;
  gap: 10px;
  .checkBoxText {
    flex: 1;
    label {
      margin: 0;
      text-align: left;
      display: inline;
    }
  }
}

.consentLink {
  text-decoration: underline;
  display: inline-block;
  margin-left: 4px;
}

.round {
  position: relative;
  width: 18px;
  height: 18px;
  margin-right: 5px;
  display: inline-block;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 20%;
  cursor: pointer;
  height: 18px;
  left: 0;
  position: absolute;
  top: 0;
  width: 18px;
}

.round label:after {
  display: block;
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  top: 3px;
  left: 3px;
  opacity: 0;
  position: absolute;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.checkBoxText {
  position: relative;
}
.checkBoxText .required {
  position: absolute;
  left: -10px;
  color: crimson;
  align-self: flex-start;
}
</style>