const state = {
    check: false,
}

const mutations = {
    CHECK(state, value) {
        state.check = value
    },
}

export default {
    namespaced: true,
    state,
    mutations
}