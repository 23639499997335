import Vue from "vue";
import VueRouter from "vue-router";
// import Home from '../views/Home.vue'
import Middleware from "../middleware/Middleware";
import Tracking from "../utils/Tracking";
Vue.use(VueRouter);

const routes = [
  {
    path: "",
    redirect: "/products",
  },
  {
    path: "/products",
    component: () =>
      import(
        /* webpackChunkName: "saleOrdering" */ "../views/productsPage/ProductsPage.vue"
      ),
    meta: {
      auth: true,
      shopType: ["buyer", "seller"],
    },
    children: [
      {
        path: "",
        name: "ProductsPage",
        redirect: "/products/all",
      },
      {
        path: "promotion",
        name: "ProductsPromotion",
        component: () =>
          import(
            /* webpackChunkName: "saleOrdering" */ "../views/productsPage/Promotion.vue"
          ),
        meta: {
          auth: true,
          mainMenu: true,
        },
      },
      {
        path: "recommend",
        name: "ProductsRecommend",
        component: () =>
          import(
            /* webpackChunkName: "saleOrdering" */ "../views/productsPage/Recommend.vue"
          ),
        meta: {
          auth: true,
          mainMenu: true,
          tt: false,
        },
      },
      {
        path: "thaibev",
        name: "ProductsThaibev",
        component: () =>
          import(
            /* webpackChunkName: "saleOrdering" */ "../views/productsPage/Thaibev.vue"
          ),
        meta: {
          auth: true,
          mainMenu: true,
          tt: false,
        },
      },
      {
        path: "all",
        name: "ProductsAll",
        component: () =>
          import(
            /* webpackChunkName: "saleOrdering" */ "../views/productsPage/All.vue"
          ),
        meta: {
          auth: true,
          mainMenu: true,
          tt: false,
        },
      },
      {
        path: "category",
        name: "ProductsCategory",
        component: () =>
          import(
            /* webpackChunkName: "saleOrdering" */ "../views/productsPage/CategorySection.vue"
          ),
        meta: {
          auth: true,
          mainMenu: true,
        },
      },
    ],
  },
  {
    path: "/products/category/:catName/:catId",
    name: "ProductsCategoryIn",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "saleOrdering" */ "../views/productsPage/CategoryIn.vue"
      ),
    meta: {
      auth: true,
    },
  },
  {
    path: "/order",
    component: () =>
      import(/* webpackChunkName: "order" */ "../views/order/OrderPage.vue"),
    meta: {
      auth: true,
      tt: false,
      mainMenu: true,
    },
    children: [
      {
        path: "",
        redirect: "/order/confirm",
        name: "Order",
      },
      {
        path: "confirm",
        name: "OrderConfirm",
        component: () =>
          import(
            /* webpackChunkName: "order" */ "../views/order/OrderConfirm.vue"
          ),
        meta: {
          filters: false,
          mainMenu: true,
          tt: false,
        },
      },
      {
        path: "progress",
        name: "OrderProgress",
        component: () =>
          import(
            /* webpackChunkName: "order" */ "../views/order/OrderProgress.vue"
          ),
        meta: {
          filters: false,
          mainMenu: true,
        },
      },
      {
        path: "history",
        name: "OrderHistory",
        component: () =>
          import(
            /* webpackChunkName: "order" */ "../views/order/OrderHistory.vue"
          ),
        meta: {
          filters: false,
          mainMenu: true,
        },
      },
    ],
  },
  {
    path: "/order/:orderId",
    name: "OrderDetail",
    props: true,
    component: () =>
      import(/* webpackChunkName: "order" */ "../views/order/OrderDetail.vue"),
  },
  {
    path: "/order/:orderId/edit",
    name: "OrderEdit",
    props: true,
    component: () =>
      import(/* webpackChunkName: "order" */ "../views/order/OrderEdit.vue"),
  },
  {
    path: "/order/:orderId/receive-update",
    name: "OrderReceive",
    props: true,
    meta: {
      shopType: ["buyer"],
    },
    component: () =>
      import(
        /* webpackChunkName: "order-recieve" */ "../views/order/OrderReceiveUpdate.vue"
      ),
  },
  {
    path: "/order/:orderId/ship-update",
    name: "OrderShip",
    props: true,
    meta: {
      shopType: ["seller"],
    },
    component: () =>
      import(
        /* webpackChunkName: "order-ship" */ "../views/order/OrderShipUpdate.vue"
      ),
  },
  {
    path: "/search/order",
    name: "SearchOrder",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "order" */ "../views/order/SearchOrderPage.vue"
      ),
  },
  {
    path: "/manage-product",
    component: () =>
      import(
        /* webpackChunkName: "saleOrdering" */ "../views/manageProduct/ManageProductPage.vue"
      ),
    meta: {
      isSeller: true,
      auth: true,
      mainMenu: true,
    },
    children: [
      {
        path: "",
        name: "ManageProduct",
        redirect: "/manage-product/all",
      },
      {
        path: "promotion",
        name: "ManagePromotion",
        component: () =>
          import(
            /* webpackChunkName: "saleOrdering" */ "../views/manageProduct/Promotion.vue"
          ),
        meta: {
          auth: true,
          mainMenu: true,
          tt: false,
        },
      },
      {
        path: "thaibev",
        name: "ManageProductThaibev",
        component: () =>
          import(
            /* webpackChunkName: "saleOrdering" */ "../views/manageProduct/Thaibev.vue"
          ),
        meta: {
          auth: true,
          mainMenu: true,
          tt: false,
        },
      },
      {
        path: "all",
        name: "ManageProductAll",
        component: () =>
          import(
            /* webpackChunkName: "saleOrdering" */ "../views/manageProduct/All.vue"
          ),
        meta: {
          auth: true,
          mainMenu: true,
          tt: false,
        },
      },
      {
        path: "category",
        name: "ManageProductCategory",
        component: () =>
          import(
            /* webpackChunkName: "saleOrdering" */ "../views/manageProduct/CategorySection.vue"
          ),
        meta: {
          auth: true,
          mainMenu: true,
          tt: false,
        },
      },
    ],
  },
  {
    path: "/products/:productId",
    name: "ProductsDetailIndex",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "saleOrdering" */ "../views/productsPage/ProductIndex.vue"
      ),
    meta: {
      auth: true,
    },
  },
  {
    path: "/search/products",
    name: "SearchProductPage",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "saleOrdering" */ "../views/SearchProductPage.vue"
      ),
    meta: {
      auth: true,
    },
  },
  {
    path: "/manage-product/category/:catName/:catId",
    name: "ManageProductCategoryIn",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "saleOrdering" */ "../views/manageProduct/CategoryIn.vue"
      ),
    meta: {
      auth: true,
    },
  },
  {
    path: "/manage-product/import-thaibev",
    name: "ImportThaibev",
    component: () =>
      import(
        /* webpackChunkName: "saleOrdering" */ "../views/manageProduct/ImportThaibev.vue"
      ),
    meta: {
      auth: true,
      tt: true,
    },
  },
  {
    path: "/manage-product/import-thaibev/category/:catName/:catId",
    name: "ImportThaibevCategory",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "saleOrdering" */ "../views/manageProduct/ImportThaibevCategory.vue"
      ),
    meta: {
      auth: true,
    },
  },
  {
    path: "/chang/register",
    name: "ChangRegister",
    component: () =>
      import(/* webpackChunkName: "chang" */ "../views/chang/Register.vue"),
    meta: {
      auth: true,
      authUser: false,
      consent: false,
    },
  },
  {
    path: "/chang/condition",
    name: "ChangCondition",
    component: () =>
      import(/* webpackChunkName: "chang" */ "../views/chang/Condition.vue"),
    meta: {
      auth: true,
      authUser: false,
    },
  },
  // {
  //   path: '/chang/condition-update',
  //   name: 'ChangUpdateCondition',
  //   component: () => import(/* webpackChunkName: "saleOrdering" */ '../views/chang/UpdateCondition.vue'),
  //   meta: {
  //     auth: true,
  //     authUser: true
  //   },
  // },
  {
    path: "/settings",
    component: () =>
      import(
        /* webpackChunkName: "saleOrdering" */ "../views/settings/SettingPage.vue"
      ),
    meta: {
      auth: true,
      tt: false,
    },
    children: [
      {
        path: "",
        name: "MainSetting",
        component: () =>
          import(
            /* webpackChunkName: "saleOrdering" */ "../views/settings/Main.vue"
          ),
        meta: {
          auth: true,
          mainMenu: true,
          tt: false,
        },
      },
      {
        path: "receive-payment/credit",
        name: "CreditSetting",
        component: () =>
          import(
            /* webpackChunkName: "saleOrdering" */ "../views/settings/Credit.vue"
          ),
        meta: {
          auth: true,
        },
      },
      {
        path: "receive-payment",
        name: "ReceivePaymentSetting",
        component: () =>
          import(
            /* webpackChunkName: "setting-receive-payment" */ "../views/settings/ReceivePayment.vue"
          ),
        meta: {
          auth: true,
        },
      },
      {
        path: "address",
        name: "AddressSetting",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "saleOrdering" */ "../views/settings/AddressPage.vue"
          ),
        meta: {
          auth: true,
        },
      },
      {
        path: "address-detail",
        name: "AddressDetailSetting",
        component: () =>
          import(
            /* webpackChunkName: "saleOrdering" */ "../views/settings/AddressDetail.vue"
          ),
        meta: {
          auth: true,
        },
      },
      {
        path: "address-detail/:id",
        name: "AddressDetailIndexSetting",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "saleOrdering" */ "../views/settings/AddressDetail.vue"
          ),
        meta: {
          auth: true,
        },
      },
      {
        path: "bank",
        name: "BankSetting",
        component: () =>
          import(
            /* webpackChunkName: "saleOrdering" */ "../views/settings/BankPage.vue"
          ),
        meta: {
          auth: true,
        },
      },
      {
        path: "bank-detail",
        name: "BankDetailSetting",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "saleOrdering" */ "../views/settings/BankDetail.vue"
          ),
        meta: {
          auth: true,
        },
      },
      {
        path: "payment-method",
        name: "PaymentMethodSetting",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "saleOrdering" */ "../views/settings/PaymentMethodPage.vue"
          ),
        meta: {
          auth: true,
        },
      },
      {
        path: "user-setting",
        name: "UserSetting",
        component: () =>
          import(
            /* webpackChunkName: "saleOrdering" */ "../views/settings/UserSetting.vue"
          ),
        meta: {
          auth: true,
        },
      },
      {
        path: "user-setting/edit",
        name: "UserEdit",
        component: () =>
          import(
            /* webpackChunkName: "saleOrdering" */ "../views/settings/UserEdit.vue"
          ),
        meta: {
          auth: true,
        },
      },
      {
        path: "user-setting/change-email",
        name: "UserChangeEmail",
        component: () =>
          import(
            /* webpackChunkName: "saleOrdering" */ "../views/settings/UserEditChangeEmail.vue"
          ),
        meta: {
          auth: true,
        },
      },
      {
        path: "user-setting/change-password",
        name: "UserChangePassword",
        component: () =>
          import(
            /* webpackChunkName: "saleOrdering" */ "../views/settings/UserEditChangePassword.vue"
          ),
        meta: {
          auth: true,
        },
      },
      {
        path: "user-setting/employee-invite",
        name: "UserEmployeeInvite",
        component: () =>
          import(
            /* webpackChunkName: "saleOrdering" */ "../views/settings/UserEmployeeInvite.vue"
          ),
        meta: {
          auth: true,
        },
      },
      {
        path: "user-setting/employee-edit/:id",
        name: "UserEmployeeEdit",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "saleOrdering" */ "../views/settings/UserEmployeeEdit.vue"
          ),
        meta: {
          auth: true,
        },
      },
      {
        path: "relation-manage",
        name: "RelationManageSetting",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "relationManageSetting" */ "../views/settings/RelationManage.vue"
          ),
        meta: {
          auth: true,
        },
      },
      {
        path: "promotion",
        name: "SettingPromotion",
        redirect: "promotion/list",
      },
      {
        path: "promotion/add",
        name: "SettingPromotionAdd",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "SettingPromotionAdd" */ "../views/settings/promotion/Add.vue"
          ),
        meta: {
          auth: true,
        },
      },
      {
        path: "promotion/edit/:id",
        name: "SettingPromotionEdit",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "SettingPromotionEdit" */ "../views/settings/promotion/Add.vue"
          ),
        meta: {
          auth: true,
        },
      },
      {
        path: "promotion/id/:id",
        name: "SettingPromotionIndex",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "SettingPromotionIndex" */ "../views/settings/promotion/Index.vue"
          ),
        meta: {
          auth: true,
        },
      },
      {
        path: "promotion/list",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "SettingPromotionList" */ "../views/settings/promotion/list/Layout.vue"
          ),
        meta: {
          auth: true,
        },
        children: [
          {
            path: "",
            name: "SettingPromotionList",
            redirect: "waiting",
          },
          {
            path: "waiting",
            name: "SettingPromotionListWaiting",
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "PromotionSetting" */ "../views/settings/promotion/list/Waiting.vue"
              ),
            meta: {
              auth: true,
            },
          },
          {
            path: "active",
            name: "SettingPromotionListActive",
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "PromotionSetting" */ "../views/settings/promotion/list/Active.vue"
              ),
            meta: {
              auth: true,
            },
          },
          {
            path: "closed",
            name: "SettingPromotionListClosed",
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "PromotionSetting" */ "../views/settings/promotion/list/Closed.vue"
              ),
            meta: {
              auth: true,
            },
          },
        ],
      },
      {
        path: "consents",
        name: "ConsentsSetting",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "consentsSetting" */ "../views/settings/ConsentsPage.vue"
          ),
        meta: {
          auth: true,
        },
      },
    ],
  },
  {
    path: "/notification",
    name: "Notification",
    props: true,
    meta: {
      auth: true,
      tt: false,
      mainMenu: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "notification" */ "../views/NotificationPage.vue"
      ),
  },
  {
    path: "/report",
    name: "Report",
    props: true,
    meta: {
      auth: true,
      tt: false,
      mainMenu: true,
    },
    component: () =>
      import(/* webpackChunkName: "report" */ "../views/report/ReportPage.vue"),
  },
  {
    path: "/cart",
    name: "CartList",
    props: true,
    meta: {
      // mainMenu: true,
    },
    component: () =>
      import(/* webpackChunkName: "cart" */ "../views/cart/CartList.vue"),
  },
  {
    path: "/cart/confirm",
    name: "CartConfirm",
    props: true,
    meta: {
      // mainMenu: true,
    },
    component: () =>
      import(/* webpackChunkName: "cart" */ "../views/cart/CartConfirm.vue"),
  },
  {
    path: "/informPayment/:sellerId/:orderId/:netPrice?",
    name: "InformPayment",
    props: true,
    meta: {},
    component: () =>
      import(
        /* webpackChunkName: "informPayment" */ "../views/informPayment/InformPayment.vue"
      ),
  },
  {
    path: "/addProduct",
    name: "AddProduct",
    props: true,
    meta: {
      shopType: ["seller"],
    },
    component: () =>
      import(
        /* webpackChunkName: "createProduct" */ "../views/manageProduct/CreateProductPage.vue"
      ),
  },
  {
    path: "/product/:productId/edit",
    name: "editProduct",
    props: true,
    meta: {
      shopType: ["seller"],
    },
    component: () =>
      import(
        /* webpackChunkName: "createProduct" */ "../views/manageProduct/CreateProductPage.vue"
      ),
  },
  {
    path: "/chooseBranch",
    name: "ChooseBranch",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "chooseBranch" */ "../views/ChooseBranch.vue"
      ),
  },
  {
    path: "/choosePermission",
    name: "ChoosePermission",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "choosePermission" */ "../views/ChoosePermission.vue"
      ),
  },
  {
    path: "/chooseSellers",
    name: "ChooseSellers",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "chooseSellers" */ "../views/ChooseSellers.vue"
      ),
  },
  {
    path: "/error",
    name: "error",
    props: true,
    meta: {
      auth: false,
    },
    component: () =>
      import(/* webpackChunkName: "error" */ "../views/ErrorPage.vue"),
  },
  {
    path: "/:catchAll(.*)",
    name: "notfound",
    meta: {
      auth: false,
    },
    component: () =>
      import(/* webpackChunkName: "notFound" */ "../views/NotFoundPage.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  // console.log(to, from)
  window.$prevRoute = from;

  if (!to.matched.length) {
    return next({ name: "notfound" });
  }

  Middleware(to, from, next);

  // next()
  window.loading(false);
});

router.afterEach((to) => {
  Tracking(to.name, to.fullPath);
});

export default router;
