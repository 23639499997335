import { minBy, maxBy } from "lodash";

export function productCompanyGroupName(item) {
    return item.brand?.company?.companyGroup?.name || "";
}

export function calStep(item) {
    const result = {
        min: null,
        max: null,
    }

    const step = item.productStepPrices

    if (!step || step.length === 0) { return null }

    result.min = minBy(step, 'price').price
    result.max = maxBy(step, 'price').price

    if (item.salePrice < result.min) result.min = item.salePrice;
    if (item.salePrice > result.max) result.max = item.salePrice;

    return result
}


export function calUnitPrice(product, qty) {

    const steps = product.productStepPrices

    if (!steps) {
        return product.salePrice
    }

    const targetStep = steps.find((step, index) => {
        if (index == steps.length - 1 && qty < step.min) return
        if (index == steps.length - 1 && qty >= step.min) return true
        if (qty >= step.min && qty <= step.max) {
            return true
        }
    })

    if (!targetStep) {
        return product.salePrice
    }

    return targetStep.price
}

export const listProductStatus = () => [
    { name: 'วางขาย', style: 'success' },
    { name: 'ไม่แสดง', style: 'out' },
    { name: 'สินค้าหมด', style: 'out' },
    { name: 'ยกเลิก', style: 'danger' },
]

export const listProductStatusApprove = () => [
    { name: 'รออนุมัติ', style: 'warning' },
    { name: 'อนุมัติ', style: 'success' },
    { name: 'รอแก้ไข', style: 'warning' },
    { name: 'ระงับ', style: 'danger' },
]