const m = {
  default: "พบบางอย่างผิดปกติ กรุณาเข้าใช้งานใหม่ภายหลัง หรือติดต่อเจ้าหน้าที่",
  noPermission: "ไม่พบสิทธิ์เข้าถึงส่วนนี้ กรุณาติดต่อผู้ดูแลระบบ",
  noAccess: "ไม่พบสิทธิ์การเข้าใช้ กรุณาติดต่อผู้ดูแลระบบ",
  noShopType:
    "ร้านค้าเอเย่นต์ยังไม่เปิดใช้งาน กรุณาติดต่อเอเย่นต์ของท่านเพื่อเปิดร้านหรือติดต่อผู้ดูแลระบบ",
};

export default (code = 100) => {
  const message =
    {
      100: m.default,
      101: m.noAccess,
      102: m.noAccess,
      103: m.noAccess,
      104: m.noAccess,
      105: m.noAccess,
      106: m.noAccess,
      107: m.noAccess,
      108: m.default,
      109: m.default,
      201: m.noShopType,
      202: m.noPermission,
      203: m.default,
      204: m.default,
    }[code] || null;

  return { message, code };
};
