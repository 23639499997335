import { POST, GET, DELETE } from "@/utils/http";
import { calUnitPrice } from "@/utils/product";

const state = {
  cartItems: [],
  cacheCount: [],
  summaryCart: 0,
  dataFetching: [],
  fetching: false,
  error: false,
};

async function fetchAddCart(product, amount) {
  try {
    const controller = new AbortController();
    const signal = controller.signal;

    const productInfeching = state.dataFetching.find((e) => {
      return e.productId == product.id;
    });

    if (productInfeching) {
      productInfeching.controller.abort();
    }

    const reqBody = {
      productId: product.id,
      amount,
      controller,
    };

    state.dataFetching.push(reqBody);

    const url = `/api/shops/${window.$permission?.myShop.id}/sellers/${window.$permission?.sellerShop.id}/carts`;

    const { status, body } = await POST(url, reqBody, null, signal);

    if (status === 200) {
      return true;
    }

    if (status === 201) {
      state.cartItems.push({
        id: body.id,
        product: product,
        amount: body.amount,
      });
      return true;
    }
    if (status === 422) {
      await window.$alert("ขออภัย พบปัญหาการเพิ่มสินค้า");
      return false;
    }
  } catch (err) {
    console.error(err);
  } finally {
    const index = state.dataFetching.findIndex(
      (e) => e.productId == product.id
    );
    if (index !== -1) {
      state.dataFetching.splice(index, 1);
    }
  }
}

async function fetchDeleteCart(cartId) {
  try {
    const controller = new AbortController();
    const signal = controller.signal;

    const url = `/api/shops/${window.$permission?.myShop.id}/carts/${cartId}`;

    const { status } = await DELETE(url, null, null, signal);

    if (status !== 204) {
      throw "not ok";
    }
  } catch (err) {
    console.error(err);
  }
}

const actions = {
  async fetchListCart() {
    try {
      state.fetching = true;
      const controller = new AbortController();
      const signal = controller.signal;

      const url = `/api/shops/${window.$permission?.myShop.id}/sellers/${window.$permission?.sellerShop.id}/carts?skip=0&limit=1000`;

      const { status, body } = await GET(url, null, null, signal);

      if (status !== 200) {
        throw "not ok";
      }

      state.cartItems = body.data;
      state.cacheCount = body.data.length;
      actions.calSummaryCart();
    } catch (err) {
      console.error(err);
      state.error = true;
    } finally {
      state.fetching = false;
    }
  },
  async addCart(com, data) {
    if (data.amount < 1) {
      return;
    }
    const indexExist = state.cartItems.findIndex(
      (e) => e.product.id == data.product.id
    );
    const setAmount = parseInt(data.amount) || 1;

    if (indexExist < 0) {
      state.cacheCount++;
      fetchAddCart(data.product, setAmount);
    } else {
      fetchAddCart(
        data.product,
        state.cartItems[indexExist].amount + setAmount
      );
      state.cartItems[indexExist].amount += setAmount;
    }

    actions.calSummaryCart();
  },
  updateCartAmount(com, data) {
    const amount = parseInt(data.amount);
    if (amount < 1) {
      return;
    }
    const indexExist = state.cartItems.findIndex(
      (e) => e.product.id == data.product.id
    );
    if (indexExist >= 0) {
      state.cartItems[indexExist].amount = amount > 0 ? amount : 1;
      fetchAddCart(data.product, amount || 1);
    }
    actions.calSummaryCart();
  },
  calSummaryCart() {
    state.summaryCart =
      state.cartItems.length > 0
        ? state.cartItems.reduce(
            (summary, item) =>
              summary + item.amount * calUnitPrice(item.product, item.amount),
            0
          )
        : 0;
  },
  removeCart(com, id) {
    fetchDeleteCart(id);
    const findIndex = state.cartItems.findIndex((a) => a.id === id);
    findIndex !== -1 && state.cartItems.splice(findIndex, 1);
    actions.calSummaryCart();
  },
};

export default {
  namespaced: true,
  state,
  actions,
};
