import GetSellers from "../hooks/getSellers";
import GetBuyers from "../hooks/getBuyers";

export async function isBuyer() {
  const { body, error } = await GetSellers(1, 1);
  if (error) {
    console.error(error);
    return;
  }

  if (body.data.length > 0) {
    return true;
  }
  return false;
}

export async function isSeller() {
  const { body, error } = await GetBuyers(1, 1);
  if (error) {
    console.error(error);
    return;
  }

  if (body.data.length > 0) {
    return true;
  }
  return false;
}
