
import { GET } from "@/utils/http";

export default async function (page = 1, limit = 1) {
    let response = ({ status: null, body: null, error: null })
    try {
        response = await GET(
            `/api/shops/${window.$permission.myShop.id}/partners/seller?page=${page}&limit=${limit}`
        );
        if (response.status !== 200) {
            response.error = "NOT_OK"
        }
        return response
    } catch (err) {
        response.error = err
        return response
    }
}