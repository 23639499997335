<template>
  <transition :name="transitionName" appear mode="out-in">
    <component :is="useLayout">
      <slot />
    </component>
  </transition>
</template>

<script>
const defaultLayout = "DefaultLayout";
export default {
  name: "AppLayout",
  computed: {
    layout() {
      return this.$route.meta.layout || defaultLayout;
    },
  },
  data() {
    return {
      transitionName: "slide-left",
      testText: "B",
      useLayout: () => import(`@/layouts/${this.layout}.vue`),
    };
  },
  watch: {
    layout() {
      this.useLayout = () => import(`@/layouts/${this.layout}.vue`);
    },
    $route(to, from) {
      const IsItABackButton = window.popStateDetected;
      window.popStateDetected = false;
      if (IsItABackButton) {
        if (from.meta.tt === false) {
          this.transitionName = "";
          return;
        }
        this.transitionName = "slide-right";
        return;
      }

      if (to.meta.tt === true) {
        this.transitionName = "slide-left";
        return;
      }

      if (to.meta.tt === false) {
        this.transitionName = "";
        return;
      }

      const toDepth = to.path.split("/").length;
      const fromDepth = from.path.split("/").length;
      if (toDepth == fromDepth) {
        this.transitionName = "";
        return;
      }
      this.transitionName = toDepth > fromDepth ? "slide-left" : "slide-right";
    },
  },
  mounted() {
    window.popStateDetected = false;
    window.addEventListener("popstate", () => {
      if (this.$route.name) {
        window.popStateDetected = true;
      }
    });
  },
};
</script>

<style lang="scss">
</style>
<style lang="scss" scoped>
.slide-left-enter,
.slide-right-leave-active {
  transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1);
  opacity: 0;
  -webkit-transform: translate(30px, 0);
  transform: translate(30px, 0);
}

.slide-left-leave-active {
  z-index: 10;
  background: #fff;
  width: 100%;
  height: 100%;
}

.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1);

  -webkit-transform: translate(-30px, 0);
  transform: translate(-30px, 0);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>